<template>
    <b-container class="library" fluid>
        <b-row class="mr-0 vertical-align">
            <b-col sm="12" lg="6">
                <span class="pl-3 title float-left">{{ $t('library.title') }}</span>
            </b-col>
            <b-col sm="12" lg="4" class="ml-auto">
                <div class="search-wrapper">
                    <font-awesome-icon :icon="['fas', 'search']" class="search-icon" />
                    <input v-model="search" class="search-input" type="text" :placeholder="$t('library.search')" @keyup="searchInputChanged()">
                </div>
            </b-col>
        </b-row>
        <b-container
            class="mt-3"
            fluid
        >
            <b-card no-body>
                <b-row no-gutters class="px-4">
                    <b-col md="9">
                        <div class="text-first">{{ $t('library.paginationHeader') }}</div>
                        <div class="counter-text">{{ $t('library.counterText', {perPage: articlesPerPage, total: totalCount}) }}</div>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>
        <b-container
            v-for="article of articles"
            :key="article.id"
            class="my-2 pointer"
            fluid
            @click="goToArticle(article)"
        >
            <b-card no-body>
                <b-row no-gutters class="py-2 px-4 h-100">
                    <b-col md="2">
                        <img
                            class="article-image"
                            :src="`${article.imageUrl}`"
                            alt="article image"
                        >
                    </b-col>
                    <b-col md="9">
                        <b-card-body>
                            <div class="text-first pb-2"> {{ $t('library.educationalGuide') }} </div>
                            <div class="text-second pb-4">
                                {{ article.title }}
                            </div>
                            <span class="article-content">
                                {{ article.content | striphtml }}
                            </span>
                        </b-card-body>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>
        <b-row class="mr-0">
            <b-col class="">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="perPage"
                    align="right"
                    class="mr-3 pt-3"
                    size="sm"
                    @change="pageChanged"
                >
                    <template v-slot:first-text><span class="first-page">{{ $t('pagination.first') }}</span></template>
                    <template v-slot:last-text><span class="last-page">{{ $t('pagination.last') }}</span></template>
                </b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    import missionService from '@/api/mission'
    import articlesService from '@/api/articles'
    export default {
        name: 'Library',
        mixins: [config],
        data() {
            return {
                search: '',
                articles: [],
                totalCount: null,
                perPage: 3,
                currentPage: 1
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            articlesPerPage() {
                return this.totalCount < this.perPage ? this.totalCount : this.perPage
            }
        },
        created() {
            this.searchArticles()
            if (this.task && this.task.id) {
                let points = this.task.points
                let finished = true
                missionService.updateTask(this.mission, this.task, points, finished)
            }
        },
        methods: {
            searchInputChanged() {
                if (this.search) {
                    this.currentPage = 1
                }
                this.searchArticles()
            },

            searchArticles() {
                articlesService.searchArticle(this.currentPage - 1, this.perPage, this.search).then((res) => {
                    this.articles = res.data
                    this.totalCount = res.totalElements
                    this.perPage = res.size
                })
            },
            pageChanged(page) {
                this.currentPage = page
                this.searchArticles()
            },
            goToArticle(article) {
                this.$router.push(
                    {
                        path: `/articles/${article.id}`
                    }
                )
            },
            goToHome() {
                this.$router.push({ path: `/home` })
            }
        }
    }
</script>

<style lang="scss" scoped>
.library {
    padding: 1rem 0 2rem;
}

.pointer {
    cursor: pointer;
}

.first-page {
    color: var(--primary-color) !important;
}

.last-page {
    color: var(--primary-color) !important;
}

.article-content {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.text-first {
    color: var(--info-color);
    font-size: 16.5px;
    text-align: left;
}

.text-second {
    color: var(--black);
    font-size: 1.25rem;
    font-weight: 700;
    text-align: left;
}

.counter-text {
    color: var(--black);
    font-size: 14px;
    text-align: left;
}

.article-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.items-middle {
    vertical-align: middle;
}

.title {
    font-weight: 700;
    font-size: 1.75rem;
    color: var(--main-content-text);
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.search-wrapper {
    transition: all 0.25s ease-in-out;
    width: 90%;
    margin: 0 auto;
    position: relative;
}

/* duplicate rule for edge */
.search-wrapper:focus-within .search-icon path {
    color: var(--primary-color);
}

.search-wrapper.focus-within svg.search-icon path {
    color: var(--primary-color);
}

.search-input {
    width: 100%;
    border: 2px solid var(--main-content-text);
    color: var(--main-content-text);
}

.search-wrapper:focus-within .search-input {
    color: var(--primary-color);
}

.margin-left {
    margin-left: 15px;
}

.search-icon {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: var(--main-content-text);
    font-size: 1.5rem;
}

input {
    background: transparent;
    padding: 4px 12px;
    text-indent: 10%;
    color: var(--black);
    height: 38px;
    border: 2px solid var(--white);
    transition: 0.15s all ease-in-out;
}

input:focus {
    outline: none;
    background: var(--white);
}

input::placeholder {
    font-size: 14px;
    color: var(--main-content-text);
    font-weight: 100;
}

@media screen and (min-width: 992px) {
    .search-wrapper {
        width: 100%;
    }
}
</style>

<style lang="scss">
.page-item .page-link {
    z-index: 3;
    color: var(--primary-color);
}

.page-item.active .page-link {
    z-index: 3;
    color: var(--white);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

</style>
